<template lang="pug">
.product-details-table
  table.product-details
    tbody
      tr.bold
        td №
        td Товары (работы, услуги)
        td Кол-во
        td Ед.
        td Цена
        td Сумма
      tr
        td 1
        td(style="text-align: start;") {{ product }}
        td 1
        td шт
        td {{ costFormatter('RUB').format(cost) }}
        td {{ costFormatter('RUB').format(cost) }}

  .summary
    .summary-row
      span Итого:
      span.center {{ costFormatter('RUB').format(cost) }}
    .summary-row
      span Без НДС:
      span.center ---
    .summary-row
      span Всего к оплате:
      span.center {{ costFormatter('RUB').format(cost) }}

  .summary-label
    span Всего наименований 1, на сумму {{ costFormatter('RUB').format(cost) }}
    span.bold {{ costAsWords }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { costFormatter } from "~/utils/formatter/costFormatter";
import { convert as convertNumberToWordsRu } from 'number-to-words-ru';

export default defineComponent({
  name: "ProductDetails",
  methods: {
    costFormatter,
  },
  props: {
    cost: {
      type: Number,
      default: 0,
    },
    product: {
      type: String,
      default: '',
    },
  },
  setup(props) {

    const costAsWords = computed(() => convertNumberToWordsRu(props.cost?.toString(), {
      currency: 'rub',
      declension: 'nominative',
    }))

    return {
      costAsWords,
    }
  }
})
</script>

<style scoped lang="scss">
.product-details-table {
  display: flex;
  flex-flow: column;
  gap: 8px;
}

.product-details {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border-spacing: 0;
  color: var(--main-text-color);
  text-align: center;
  font-size: 12px;

  tr {
    display: grid;
    grid-template-columns: 40px 5fr 60px 40px repeat(2, minmax(80px, 1fr));

    &.bold {
      font-size: 10px;
      font-weight: bold;
    }
  }

  td {
    padding: 2px;
    border: 1px solid rgb(0, 0, 0);
  }
}

.summary {
  display: flex;
  flex-flow: column;
  gap: 0;
  font-size: 12px;
  font-weight: bold;

  .summary-row {
    display: grid;
    grid-template-columns: 1fr 80px;
    gap: 32px;
    text-align: end;

    .center {
      text-align: center;
    }
  }
}

.summary-label {
  display: flex;
  flex-flow: column;
  gap: 0;
  font-size: 12px;

  .bold {
    font-weight: bold;
  }
}
</style>
