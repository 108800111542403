<template lang="pug">
.payment-invoice
  #payment-invoice.invoice-data(:class="blurred && 'blurred'" style="font-family: Nunito;")
    .warning-header
      span ВНИМАНИЕ! НОВЫЕ БАНКОВСКИЕ РЕКВИЗИТЫ! ПРОВЕРЬТЕ ПЕРЕД ОПЛАТОЙ СЧЕТА!
      span ВНИМАНИЕ! НЕ ДОПУСКАЕТСЯ ОПЛАТА С ЛИЧНОГО СЧЕТА ФИЗЛИЦА
      span (В ТОМ ЧИСЛЕ НЕ ДОПУСКАЕТСЯ ОПЛАТА КАРТОЙ)!

    bank-details-table(:details="bankDetails")

    span.bill-label
      span Счет-оферта на оплату № {{ ' ' }}
      span(v-if="!blurred && invoice.invoiceNumber") {{ invoice.invoiceNumber }} от {{ billDate }}
      span.empty(v-else) Номер будет присвоен после заполнения всех полей

    .executor-details
      span Исполнитель:
      .executor {{ executor }}

    .customer-details
      span Заказчик:
      .customer(v-if="!blurred && customerString") {{ customerString }}
      .customer.empty(v-else) После заполнения всех полей, здесь будет отображена Ваша компания

    product-details.price-table(:cost="invoice.cost" :product="getInvoiceProduct(invoice?.tariff?.title, invoice?.tariffDuration)")

    .bill-agreement
      span.label ДОГОВОР-ОФЕРТА
      ol
        li Настоящий счет является договором-офертой в соответствии со ст.435 ГК РФ. Настоящая оферта действительна и ее акцепт возможен в течение 20 (двадцати) рабочих дней с даты выписки счета (но не позднее даты окончания акции, если цена является акционной), если иное не оговорено в разделе Особые условия настоящего счета.
        li Оплата, в том числе частичная, Заказчиком или другим лицом по его указанию со ссылкой на настоящий счет означает ознакомление и согласие Заказчика со всеми условиями, изложенными по ссылке: <a class="link" href="https://nposys.ru/terms/" target="_blank">https://nposys.ru/terms/</a>, а также является фактическим заключением договора на оказание услуг, поименованных в счете. В случае частичной оплаты окончательный расчет производится в течение трех рабочих дней с момента подписания Акта об оказании услуг.
        li Оплата, в том числе частичная, Заказчиком или другим лицом по его указанию со ссылкой на настоящий счет означает согласие Заказчика со всеми условиями настоящего договора-оферты, изложенными по ссылке <a class="link" href="https://nposys.ru/tender-monitor-public-offer/" target="_blank">https://nposys.ru/tender-monitor-public-offer/</a>, а также является фактическим заключением договора на оказание услуг, поименованных в счете. В случае частичной оплаты окончательный расчет производится в течение трех рабочих дней с момента подписания Акта об оказании услуг.

    .bill-seal
      .job-title
        span.bold Директор
        span.line должность
      .seal
        img(src="/pics/bill/signature.png" alt="Печать и подпись")
      .headmaster
        span.bold Шнитко Александр Геннадьевич
        span.line ФИО

  .blur-data(v-if="blurred")
    span Для предпоказа заполните все поля и нажмите на кнопку "Сформировать счет"
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { format } from 'date-fns';
import { getInvoiceProduct } from "~/utils/tariffs/priceGenerator";

import BankDetailsTable from "~/components/bills/BankDetailsTable.vue";
import ProductDetails from "~/components/bills/ProductDetails.vue";

import ruLocale from "date-fns/locale/ru";
import type { InvoiceI } from "~/const/tariffs";

export default defineComponent({
  name: "PaymentInvoice",
  methods: {
    getInvoiceProduct,
  },
  components: {
    ProductDetails,
    BankDetailsTable,
  },
  props: {
    invoice: {
      type: Object as PropType<InvoiceI>,
      default: () => ({}),
    },
    blurred: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {

    const billDate = format(props.invoice?.dateCreate || new Date(), 'PPP', { locale: ruLocale });

    const bankDetails = {
      receiver: 'ООО НПО "Система"',
      bankTitle: 'ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО)',
      inn: '5408272521',
      kpp: '540801001',
      currentAccount: '40702810212685218962',
      correspondentAccount: '30101810145250000411',
      BIC: '044525411',
    }

    const executor = 'ООО НПО "Система", ИНН 5408272521, КПП 540801001, 630128, г. Новосибирск, ул. Демакова, д. 30, оф. 502, тел.: +7 (383) 335-68-08, +7 (383) 286-82-50'
    const customerString = computed(() => {
      if (!props.invoice) return undefined
      return [
        props.invoice.companyTitle,
        props.invoice.companyInn && `ИНН: ${ props.invoice.companyInn }`,
        props.invoice.companyKpp && `КПП: ${ props.invoice.companyKpp }`,
        props.invoice.companyLegalAddress,
      ].filter(Boolean).join(', ')
    })

    return {
      billDate,
      executor,
      bankDetails,
      customerString,
    }
  }
})
</script>

<style scoped lang="scss">
.payment-invoice {
  display: flex;
  width: 630px;
  height: 891px;
  background-color: white;
  box-shadow: 0 11px 30px rgba(153, 159, 172, 0.2);
  position: relative;

  .invoice-data {
    padding: 32px 24px;

    &.blurred {
      filter: blur(6px);
    }
  }

  .blur-data {
    position: absolute;
    padding: 64px 64px 24px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    //align-items: center;
    text-align: center;
    justify-content: center;

    font-size: 20px;
    font-weight: bold;

    span {
      margin-top: 200px;
    }
  }
}

.invoice-data {
  display: flex;
  flex-flow: column;
  gap: 12px;
  box-sizing: border-box;
}

.warning-header {
  display: flex;
  flex-flow: column;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: var(--main-red-color);
  text-align: center;
}

.bill-label {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  border-bottom: 1px solid var(--main-color-black);
}

.executor-details,
.customer-details {
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 32px;

  font-size: 12px;

  .executor, .customer {
    font-weight: bold;
  }
}

.price-table {
  margin-top: 12px;
}

.empty {
  color: var(--default-blue-color);
}

.link {
  cursor: pointer;
  color: var(--default-blue-color);
}

.bill-agreement {
  .label {
    font-size: 11px;
    font-weight: bold;
  }

  ol {
    font-size: 8px;
    line-height: 10px;
    padding-left: 12px;
  }
}

.bill-seal {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 140px minmax(0, 1fr);
  gap: 40px;

  .seal {
    width: inherit;

    img {
      width: 100%;
    }
  }
}

.job-title, .headmaster {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 12px;

  .bold {
    font-weight: bold;
  }

  .line {
    width: 100%;
    border-top: 1px solid black;
  }
}
</style>
