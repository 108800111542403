<template lang="pug">
table.bank-details
  tbody
    tr
      td(colspan="2") {{ details.bankTitle }}
      td(style="border-left: 1px solid rgb(0, 0, 0); border-bottom: 1px solid rgb(0, 0, 0);") БИК
      td(style="border-left: 1px solid rgb(0, 0, 0); border-bottom: 1px solid rgb(0, 0, 0);") {{ details.BIC }}
    tr(style="border-bottom: 1px solid rgb(0, 0, 0);")
      td(colspan="2") Банк получателя
      td(style="border-left: 1px solid rgb(0, 0, 0);") Корреспондентский счет
      td(style="border-left: 1px solid rgb(0, 0, 0);") {{ details.correspondentAccount }}
    tr
      td(style="border-bottom: 1px solid rgb(0, 0, 0);") ИНН {{ details.inn }}
      td(style="border-left: 1px solid rgb(0, 0, 0); border-bottom: 1px solid rgb(0, 0, 0);") КПП {{ details.kpp }}
      td(rowspan="3" style="border-left: 1px solid rgb(0, 0, 0);") Расчетный счет
      td(rowspan="3" style="border-left: 1px solid rgb(0, 0, 0);") {{ details.currentAccount }}
    tr
      td(colspan="2") {{ details.receiver }}
    tr
      td(colspan="2") Получатель
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "BankDetailsTable",
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
  }
})
</script>

<style scoped lang="scss">
.bank-details {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  border: 1px solid rgb(0, 0, 0);
  margin-bottom: 16px;
  color: var(--main-text-color);

  td {
    padding: 2px;
    align-content: start;
  }
}
</style>
