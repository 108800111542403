<template lang="pug">
.bill-page__content
  .panels
    settings-panel
      template(#header) Тариф
      radio-group(
        id="tariff-invoice-options"
        :model-value="radioValue"
        :options="radioOptions"
        :disabled="showPayment"
        @update:modelValue="changeSelectedTariff"
      )
        template(#label="{ option }")
          span(v-html="option.title")

      .toggle-group(@click="onToggleClick")
        ui-icon(:icon="showOtherTariffs ? UiIconNames.Chevron_Up : UiIconNames.Chevron_Down")
        span {{ showOtherTariffs ? 'Свернуть' : (showPayment ? 'Для изменения выбора нажмите кнопку "Редактировать"' : 'Изменить выбор') }}

    settings-panel(v-if="isTmManager")
      template(#header) Стоимость
      cost-input(v-model="invoice.cost" placeholder="Стоимость услуги" :disabled="showPayment")

    settings-panel
      template(#header) Заказчик
      span.bold-label(v-if="!isTmManager || !showPayment") Выберите компанию из списка:
      form-input-container(v-if="!isTmManager || !showPayment" label="Компания" style="margin-bottom: 12px;")
        company-selector(
          mini
          v-model="selectedCompany"
          placeholder="Введите наименование и ИНН и выберите компанию из списка"
          :disabled="showPayment"
          @select:clear="resetSelect"
          @select:change="setSelectedCompany"
        )
      span.bold-label(v-if="!isTmManager || !showPayment") Или заполните поля вручную:
      validation-row(
        not-empty
        watch-validation
        v-model="invoice.companyTitle"
        label="Название"
        placeholder="Название компании"
        :disabled="showPayment"
        @error="errorChecker('companyTitle')"
        @correct="correctChecker('companyTitle')"
        @blur:modelValue="blurInput"
      )
      .grid-row
        validation-row(
          custom
          not-empty
          watch-validation
          v-model="invoice.companyInn"
          label="ИНН"
          placeholder="Введите ИНН"
          :disabled="showPayment"
          :custom-checker="customInnChecker"
          @error="errorChecker('companyInn')"
          @correct="correctChecker('companyInn')"
          @blur:modelValue="blurInput"
        )
        validation-row(
          custom
          watch-validation
          hide-asterisk
          v-model="invoice.companyKpp"
          label="КПП"
          placeholder="Введите КПП"
          :disabled="showPayment"
          :custom-checker="customKppChecker"
          @error="errorChecker('companyKpp')"
          @correct="correctChecker('companyKpp')"
          @blur:modelValue="blurInput"
        )
      validation-row(
        not-empty
        watch-validation
        :model-value="invoice.companyLegalAddress"
        label="Юридический адрес"
        @error="errorChecker('companyLegalAddress')"
        @correct="correctChecker('companyLegalAddress')"
      )
        form-textarea(
          v-model="invoice.companyLegalAddress"
          placeholder="Укажите адрес"
          :disabled="showPayment"
          :max-length="255"
          :show-word-limit="true"
          @blur:modelValue="blurInput"
        )
      template(v-if="!showPayment")
        ui-button.save-button(type="tertiary" :disabled="!isInvoiceCorrect" :loader="paymentLoader" @click="saveInvoice") Сформировать счет
        span.incorrect-message(v-if="!isInvoiceCorrect") Для формирования счета заполните все обязательные поля
        span.error-message(v-if="paymentError") Произошла ошибка при формировании счета, попробуйте еще раз
      .actions(v-else)
        ui-button.save-button(:icon-left="UiIconNames.Icon_Download" :loader="pdfLoader" @click="downloadInvoice") Скачать счет
        ui-button(v-if="isTmManager || invoice.status?.id !== PaidInvoiceStatus" type="secondary" @click="editInvoice") Редактировать данные

  payment-invoice(
    :blurred="!showPayment"
    :invoice="invoice"
  )
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { customInnChecker, customKppChecker } from "~/utils/checkers/checkers";
import { useCompanyManagement } from "~/components/pages/settings/companyManagement/useCompanyManagement";
import { useForm } from "~/use/other/useForm";
import { costTariff } from "@/utils/formatter/costFormatter";
import { useApi } from "~/use/api/useApi";
import { generateNumberId } from "~/utils/uniqueGenerator/generator";
import { getTariffCost, getTariffRadioOption } from "~/utils/tariffs/priceGenerator";
import { downloadPDF } from "@/utils/download/pdfDownloader";
import { usePromo } from "~/use/other/usePromo";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import SettingsPanel from "~/components/pages/settings/common/SettingsPanel.vue";
import RadioGroup from "~/components/ui/radio/RadioGroup.vue";
import UiIcon from "~/components/ui/icon/UiIcon.vue";
import FormInputContainer from "~/components/ui/form/container/FormInputContainer.vue";
import CompanySelector from "~/components/selector/CompanySelector.vue";
import ValidationRow from "~/components/ui/form/input/ValidationRow.vue";
import FormTextarea from "~/components/ui/form/input/FormTextarea.vue";
import UiButton from "~/components/ui/button/UiButton.vue";
import PaymentInvoice from "~/components/bills/PaymentInvoice.vue";
import FormInput from "~/components/ui/form/input/FormInput.vue";
import CostInput from "~/components/ui/form/input/CostInput.vue";

import type { PropType } from "vue";
import { type InvoiceI, type TariffI, TariffsListConst, TariffsPeriodConst } from "~/const/tariffs";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import { PaidInvoiceStatus } from "~/const/statuses";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  name: "BillPage",
  components: {
    CostInput,
    FormInput,
    UiIcon,
    RadioGroup,
    FormInputContainer,
    UiButton,
    FormTextarea,
    ValidationRow,
    CompanySelector,
    SettingsPanel,
    PaymentInvoice,
  },
  methods: {
    costTariff,
    customInnChecker,
    customKppChecker,
  },
  emits: [
    'bill:update',
  ],
  props: {
    bill: {
      type: Object as PropType<InvoiceI>,
      default: () => ({}),
    },
  },
  setup(props, context) {

    const invoice = ref<InvoiceI>(cloneDeep(props.bill))

    const { getTariffPromoCost } = usePromo();
    const { isTmManager } = useUserAccess();

    const showOtherTariffs = ref(false);
    const pdfLoader = ref(false);

    const selectedTariffInfo = computed(() => TariffsListConst.find(e => e.id === invoice.value.tariff?.id))

    const showPayment = ref(!!invoice.value?.id);
    const paymentLoader = ref(false);
    const paymentError = ref(false);

    /** список возможных тарифов для выбора */
    const tariffList = computed(() => {
      return TariffsListConst.filter(t => t.isCostFinal).reduce((res: any, item: TariffI) => {
        TariffsPeriodConst.forEach((period: any) => {
          const cost = getTariffCost(item?.cost, period.duration, period.discount)
          const discountCost = getTariffPromoCost(cost, item?.id, period.duration)

          res.push({
            id: `${item?.id}-${period.duration}`,
            cost: cost,
            discountCost: discountCost,
            title: getTariffRadioOption(item?.title, period.duration, cost, discountCost),
          })
        })
        return res;
      }, []);
    })

    const radioValue = computed(() => `${ invoice.value?.tariff?.id }-${ invoice.value?.tariffDuration }`);
    const radioOptions = computed(() => showOtherTariffs.value ? tariffList.value : tariffList.value.filter(e => e.id === radioValue.value))

    const finalCost = computed(() => {
      const value = tariffList.value.find(e => e.id === radioValue.value)
      return value?.discountCost || value?.cost
    })

    const {
      editableCompany,
      getMyCompany,
      saveMyCompany,
    } = useCompanyManagement();

    const {
      errList,
      errorChecker,
      correctChecker,
    } = useForm(() => {});

    const isInvoiceCorrect = computed(() => !errList.value.length && ![
      invoice.value.companyTitle,
      invoice.value.companyInn,
      invoice.value.companyLegalAddress,
      invoice.value.tariff?.id,
      invoice.value.tariffDuration,
      invoice.value.cost,
    ].filter(e => !e).length)

    if (invoice.value && !invoice.value.id) {
      if (!invoice.value.cost) invoice.value.cost = finalCost.value

      getMyCompany()
        .then((company) => setInvoiceCompanyInfo(company))
    }

    const selectedCompany = ref(null);

    function resetSelect(clear = true) {
      selectedCompany.value = null
      if (clear) setInvoiceCompanyInfo(editableCompany.value)
    }

    function setInvoiceCompanyInfo(company: any) {
      invoice.value.companyTitle = company?.title
      invoice.value.companyInn = company?.inn
      invoice.value.companyKpp = company?.kpp
      invoice.value.companyLegalAddress = company?.legalAddress
    }

    function setSelectedCompany(company: any) {
      selectedCompany.value = company
      setInvoiceCompanyInfo(company)
    }

    function blurInput() {
      resetSelect(false)
    }

    function changeSelectedTariff(value: string) {
      invoice.value.tariff = TariffsListConst.find(e => e.id === Number(value.split('-')[0]))
      invoice.value.tariffDuration = Number(value.split('-')[1])
      invoice.value.cost = finalCost.value
      showOtherTariffs.value = false
    }

    function onToggleClick() {
      if (!showPayment.value) showOtherTariffs.value = !showOtherTariffs.value
    }

    function saveInvoice() {
      if (!isInvoiceCorrect.value) return

      paymentLoader.value = true
      paymentError.value = false

      const invoiceNumber = invoice.value?.invoiceNumber || `A${ editableCompany.value?.id }-${ generateNumberId(3) }`

      const payload : any = {
        invoiceNumber: invoiceNumber,
        companyTitle: invoice.value.companyTitle,
        companyInn: invoice.value.companyInn,
        companyKpp: invoice.value.companyKpp || null,
        companyLegalAddress: invoice.value.companyLegalAddress,
        tariff: `/api/tariffs/${invoice.value.tariff?.id}`,
        tariffDuration: invoice.value.tariffDuration,
        cost: invoice.value.cost,
      };

      (invoice.value.id
        ? useApi().invoices.patchInvoice<InvoiceI>(invoice.value.id, payload)
        : useApi().invoices.postInvoice<InvoiceI>(payload))
          .then((response) => {
            invoice.value = response
            context.emit('bill:update', response)
            showPayment.value = true
          })
          .catch(() => paymentError.value = true)
          .finally(() => paymentLoader.value = false)
    }

    function editInvoice() {
      showPayment.value = false
    }

    function downloadInvoice() {
      downloadPDF(pdfLoader, invoice.value?.invoiceNumber, invoice.value?.dateCreate)
    }

    return {
      showPayment,
      isInvoiceCorrect,
      finalCost,
      selectedCompany,
      selectedTariffInfo,
      invoice,
      radioOptions,
      radioValue,
      showOtherTariffs,
      paymentLoader,
      paymentError,
      errList,
      isTmManager,
      pdfLoader,
      downloadInvoice,
      errorChecker,
      correctChecker,
      editInvoice,
      saveInvoice,
      blurInput,
      resetSelect,
      setSelectedCompany,
      onToggleClick,
      changeSelectedTariff,
      PaidInvoiceStatus,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
.bill-page__content {
  display: grid;
  grid-template-columns: 1fr 630px;
  gap: 32px;

  .panels {
    display: flex;
    flex-flow: column;
    gap: 24px;
  }

  .grid-row {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 12px;
  }

  .bold-label {
    color: var(--default-blue-color);
    font-weight: bold;
  }

  .save-button {
    width: fit-content;
  }

  .incorrect-message {
    font-size: 12px;
    color: var(--text-warning-color);
  }

  .error-message {
    font-size: 12px;
    color: var(--main-red-color);
  }

  .actions {
    display: flex;
    flex-flow: row;
    gap: 8px;
  }

  .toggle-group {
    display: flex;
    flex-flow: row;
    gap: 4px;

    justify-content: center;
    align-items: center;

    font-size: 12px;
    line-height: 16px;
    color: var(--secondary-text-color);
    cursor: pointer;
  }
}
</style>
