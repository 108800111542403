<template lang="pug">
.invoice-page(v-if="!showBillPage")
  slot

  alert-message(v-if="globalLoader && !isTmManager" type="info")
    ui-loader(label="Проверка наличия счетов на оплату" mini inline)

  alert-message(
    v-else-if="totalResults === 0 && !invoiceLoader && !isTmManager"
    hide-icon
    type="default"
    label="У Вас нет сформированных счетов на оплату"
  )
    template(#content)
      span Для некоторых тарифов Вы можете самостоятельно сформировать счет на оплату. Для этого нажмите кнопку <b>"Выбрать план"</b> возле нужного тарифа (доступно только юридическим лицам и индивидуальным предпринимателям).

  settings-panel(v-else)
    template(#header) Счета
    ui-table(
      :data="data"
      :columns="columns"
      :loader="invoiceLoader"
      :has-error="invoiceError"
      :header-sticky="true"
      v-model="preFiltrationForm"
      @apply="jumpToPage(1)"
    )
      template(#dateCreate="{ rowData }") {{ rowData.dateCreate ? formatDateTime(rowData.dateCreate) : 'Нет данных' }}
      template(#customer="{ rowData }")
        span {{ rowData.company?.title }}
        span.secondary(v-if="rowData.company?.inn") ИНН: {{ rowData.company?.inn }}
      template(#product="{ rowData }") {{ getInvoiceProduct(rowData.tariff?.title, rowData.tariffDuration) }}
      template(#cost="{ rowData }") {{ costTariff().format(rowData.cost) }} ₽
      template(#status="{ rowData }")
        .flex-row
          ui-tag(:name="rowData.status?.title" size="small" :type="rowData.status?.id === PaidInvoiceStatus ? 'success' : 'warning'")
          icon-button(
            v-if="isTmManager"
            :size="16"
            :icon="UiIconNames.Icon_Edit"
            tooltip-title="Редактировать статус счета"
            @click="editInvoiceStatus(rowData)"
          )
      template(#actions="{ rowData }")
        span.link(@click="goToBillPage(rowData)") {{ rowData.status?.id === PaidInvoiceStatus && !isTmManager ? 'Просмотр' : 'Перейти' }}

    ui-pagination(
      :count-results="data.length"
      :total-results="totalResults"
      :current-page="preFiltrationForm.page"
      :total="totalPages"
      @update:current-page="jumpToPage($event, false)"
    )

  #tariffs(v-if="!isTmManager" style="margin-bottom: 64px;")
    tariff-selector(@create:invoice="createInvoice")

#invoice-page(v-else)
  go-back.go-back(bold :class="isTmManager && 'margin'" @click="goBack") Вернуться назад
  .page-title__wrapper(v-if="!isTmManager")
    h1.main-page-title Формирование счета на оплату
  bill-page(:bill="invoice" @bill:update="updateInvoiceInfo")

edit-invoice-status-dialog(v-if="showStatusDialog" v-model:show="showStatusDialog" :invoice="invoice" :options="invoiceStatuses" @success="updateInvoiceInfo")
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";
import { scrollToTop } from "~/utils/scroller/documentScroller";
import { useApi } from "~/use/api/useApi";
import { formatDateTime } from "@/utils/formatter/dateFormatter";
import { getInvoiceProduct } from "~/utils/tariffs/priceGenerator";
import { costTariff } from "@/utils/formatter/costFormatter";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { storeToRefs } from "pinia";

import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import SettingsPage from "@/components/pages/settings/common/SettingsPage.vue";
import MyTariff from "@/components/pages/settings/tariffPage/MyTariff.vue";
import TariffSelector from "@/components/pages/settings/tariffPage/TariffSelector.vue";
import BillPage from "~/views/settings/tariff/BillPage.vue";
import GoBack from "~/components/other/GoBack.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";
import UiLoader from "~/components/ui/loader/UiLoader.vue";
import UiIcon from "~/components/ui/icon/UiIcon.vue";
import UiTable from "~/components/ui/table/UiTable.vue";
import UiTag from "~/components/ui/tag/UiTag.vue";
import UiPagination from "~/components/ui/pagination/UiPagination.vue";
import ClientFilter from "~/components/filters/services/ClientFilter.vue";
import IconButton from "~/components/ui/button/IconButton.vue";
import EditInvoiceStatusDialog from "~/views/settings/tariff/EditInvoiceStatusDialog.vue";

import type { InvoiceI } from "~/const/tariffs";
import type { BaseObjectI } from "~/stores/manuals/ManualsInterface";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import useTmManagerStore from "~/stores/tenmonManager/useTmManagerStore";
import { PaidInvoiceStatus } from "~/const/statuses";

function getDefaultInvoice() : InvoiceI {
  return {
    id: undefined,
    invoiceNumber: '',
    companyTitle: '',
    companyInn: '',
    companyKpp: '',
    companyLegalAddress: '',
    tariff: undefined,
    tariffDuration: undefined,
    cost: undefined,
    dateCreate: '',
  }
}

export default defineComponent({
  name: "InvoicePage",
  components: {
    EditInvoiceStatusDialog,
    IconButton,
    UiPagination,
    UiTag,
    UiTable,
    UiIcon,
    UiLoader,
    AlertMessage,
    GoBack,
    BillPage,
    SettingsPage,
    SettingsPanel,
    MyTariff,
    TariffSelector,
    ClientFilter,
  },
  methods: {
    getInvoiceProduct,
    formatDateTime,
    costTariff,
  },
  setup() {

    const showBillPage = ref(false);

    const invoiceLoader = ref(true);
    const invoiceError = ref(false);
    const globalLoader = ref(true);

    const invoice = ref<InvoiceI>(getDefaultInvoice());

    const data = ref<InvoiceI[]>([]);
    const invoiceStatuses = ref<BaseObjectI[]>([]);

    const showStatusDialog = ref(false);

    const totalPages = ref(1);
    const totalResults = ref(0);

    const { isTmManager } = useUserAccess();

    const tenmonManagerStore = useTmManagerStore();
    const { selectedCompanyInfo } = storeToRefs(tenmonManagerStore);

    const preFiltrationForm = ref({
      page: 1,
      customerCompany: selectedCompanyInfo.value,
      sorting: [{ title: 'dateCreate', order: 'desc' }]
    })

    /** обновление результатов, если менеджером был выбран клиент */
    if (isTmManager.value) {
      watch(selectedCompanyInfo, () => {
        preFiltrationForm.value.customerCompany = selectedCompanyInfo.value || null
        jumpToPage(1)
      })
    }

    /** запрашиваем список статусов для менеджеров */
    if (isTmManager.value) {
      useApi().invoices.getInvoiceStatuses<BaseObjectI[]>()
        .then((response) => invoiceStatuses.value = response)
        .catch(() => {})
    }

    // company.id status.id
    const columns = computed(() => ({
      dateCreate: { size: 156, bold: true, isSort: true, headerLabel: 'Дата создания' },
      invoiceNumber: { size: 100, bold: true, headerLabel: 'Номер счета' },
      customer: { invisible: !isTmManager.value, size: 200, headerLabel: 'Заказчик', filterComponent: selectedCompanyInfo.value ? undefined : ClientFilter, value: preFiltrationForm.value.customerCompany?.title || '' },
      product: { size: isTmManager.value ? 244 : 444, headerLabel: 'Услуга' },
      cost: { size: 110, headerLabel: 'Цена' },
      status: { size: 164, headerLabel: 'Статус' },
      actions: { size: 100, headerLabel: 'Действия' },
    }))

    function getInvoicesList(resetPages = true) {

      data.value = []

      invoiceLoader.value = true
      invoiceError.value = false

      if (resetPages) {
        totalResults.value = 0
        totalPages.value = 1
      }

      useApi().invoices.getInvoice(preFiltrationForm.value)
        .then((response: any) => {
          totalResults.value = Number(response['totalItems'])
          totalPages.value = Math.ceil(response['totalItems'] === 0 ? 1 : (response['totalItems'] / 30))
          data.value = response['member']
        })
        .catch(() => invoiceError.value = true)
        .finally(() => {
          globalLoader.value = false
          invoiceLoader.value = false
        })
    }

    getInvoicesList()

    function createInvoice(payload: any) {
      invoice.value = getDefaultInvoice()

      invoice.value.tariff = payload?.tariff
      invoice.value.tariffDuration = payload?.duration
      scrollToTop('instant')
      showBillPage.value = true;
    }

    function goBack() {
      scrollToTop('instant')
      showBillPage.value = false;
    }

    function goToBillPage(bill: InvoiceI) {
      invoice.value = bill
      scrollToTop('instant')
      showBillPage.value = true;
    }

    function jumpToPage(p: number, resetPages = true) {
      preFiltrationForm.value.page = p
      getInvoicesList(resetPages)
    }

    function editInvoiceStatus(bill: InvoiceI) {
      invoice.value = bill
      showStatusDialog.value = true
    }

    function updateInvoiceInfo(bill: InvoiceI) {
      const index = data.value.findIndex(e => e.id === bill.id)

      if (index !== -1) data.value[index] = bill
      else data.value.unshift(bill)
    }

    return {
      data,
      columns,
      showBillPage,
      invoice,
      invoiceLoader,
      invoiceError,
      globalLoader,
      totalPages,
      totalResults,
      preFiltrationForm,
      isTmManager,
      showStatusDialog,
      invoiceStatuses,
      updateInvoiceInfo,
      editInvoiceStatus,
      jumpToPage,
      goBack,
      createInvoice,
      goToBillPage,
      UiIconNames,
      PaidInvoiceStatus,
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/pages/main";

.invoice-page {
  display: flex;
  flex-flow: column;
  gap: 32px;
}

#invoice-page {
  font-size: 14px;
}

#tariffs {
  scroll-margin-top: 60px;
}

.go-back {
  margin-top: 24px;

  &.margin {
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.main-page-title {
  line-height: 32px;
  padding: 16px 0 32px !important;
}

.link {
  cursor: pointer;
  font-weight: bold;

  display: flex;
  flex-flow: row;
  gap: 8px;
}

.secondary {
  font-size: 12px;
  color: var(--secondary-text-color);
}

.flex-row {
  display: flex;
  flex-flow: row;
  gap: 8px;
}
</style>
