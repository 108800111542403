import { jsPDF } from "jspdf";
import { formatDateDMY } from "~/utils/formatter/dateFormatter";

import NunitoRegular from "~/assets/fonts/Nunito-Regular-normal.js";
import NunitoBold from "~/assets/fonts/Nunito-Bold-bold.js";

// todo ol, image quality
export function downloadPDF(loader: Ref<boolean>, invoiceNumber: number, invoiceDate: string) {

  loader.value = true;

  const doc = new jsPDF();
  const elementHTML = document.querySelector("#payment-invoice");

  doc.addFileToVFS('Nunito-Regular-normal.ttf', NunitoRegular);
  doc.addFont('Nunito-Regular-normal.ttf', 'Nunito', 'normal');

  doc.addFileToVFS('Nunito-Bold-bold.ttf', NunitoBold);
  doc.addFont('Nunito-Bold-bold.ttf', 'Nunito', 'bold');

  doc.setFont('Nunito')

  doc.html(elementHTML, {
    callback: function(doc) {
      loader.value = false;
      doc.save(`Счет №${ invoiceNumber } от ${ formatDateDMY(invoiceDate) } на оплату услуг ТендерМонитора.pdf`);
    },
    margin: [10, 10, 10, 10],
    autoPaging: 'text',
    x: 0,
    y: 0,
    width: 190, //target width in the PDF document
    windowWidth: 675 //window width in CSS pixels
  });
}
