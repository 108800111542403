<template lang="pug">
ui-dialog(
  modal
  closable
  width="600px"
  header="Изменение статуса счета"
  :z-index="3"
  :visible-prop="show"
  @content:hide="hide"
)
  template(#content)
    .edit-invoice
      .edit-invoice__info
        .row
          span.secondary Номер счета
          span {{ invoice.invoiceNumber }}
        .row
          span.secondary Заказчик
          span {{ invoice.company.title }}
        .row
          span.secondary Цена
          span {{ costTariff().format(invoice.cost) }} ₽
        .column
          span.secondary Услуга
          span {{ getInvoiceProduct(invoice.tariff?.title, invoice.tariffDuration) }}
      ui-select(
        full-info
        track-by="id"
        placeholder="Выберите статус"
        label="Статус счета"
        :teleported="true"
        :options="options"
        v-model="invoiceStatus"
      )
    .error-message(v-if="error") Произошла ошибка при обновлении данных, попробуйте позже
  template(#actions)
    ui-button(type="secondary" @click="hide") Отменить
    ui-button(:loader="loader" @click="saveChanges") Сохранить
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { getInvoiceProduct } from "~/utils/tariffs/priceGenerator";
import { useApi } from "@/use/api/useApi";

import type { PropType } from "vue";
import type { BaseObjectI } from "@/stores/manuals/ManualsInterface";
import type { InvoiceI } from "~/const/tariffs";

import UiButton from "@/components/ui/button/UiButton.vue";
import UiDialog from "@/components/ui/dialog/UiDialog.vue";
import UiSelect from "~/components/ui/select/select/UiSelect.vue";
import { costTariff } from "~/utils/formatter/costFormatter";

export default defineComponent({
  name: "EditInvoiceStatusDialog",
  methods: {
    costTariff,
    getInvoiceProduct,
  },
  emits: [
    'update:show',
    'success',
  ],
  components: {
    UiSelect,
    UiButton,
    UiDialog,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Object as PropType<InvoiceI>,
      default: () => ({}),
    },
    options: {
      type: Array as PropType<BaseObjectI[]>,
      default: () => [],
    },
  },
  setup(props, context) {

    const loader = ref(false);
    const error = ref(false);

    const invoiceStatus = ref(props.invoice.status)

    function hide() {
      context.emit('update:show', false)
    }

    function saveChanges() {
      loader.value = true;
      error.value = false;

      useApi().invoices.patchInvoice(props.invoice.id, { status: `/api/invoice-statuses/${invoiceStatus.value.id}` })
        .then((response) => {
          context.emit('success', response)
          hide()
        })
        .catch(() => error.value = true)
        .finally(() => loader.value = false)
    }

    return {
      loader,
      error,
      invoiceStatus,
      hide,
      saveChanges,
    }
  }
})
</script>

<style scoped lang="scss">
@import 'assets/styles/mixin/fonts';

.edit-invoice {
  display: grid;
  grid-template-columns: auto 260px;
  grid-column-gap: 24px;

  .edit-invoice__info {
    display: flex;
    flex-flow: column;
    gap: 8px;

    font-size: 14px;
    line-height: 20px;
  }

  .row {
    display: grid;
    grid-template-columns: 100px auto;
  }

  .column {
    display: flex;
    flex-flow: column;
    gap: 4px;
  }

  .secondary {
    font-size: 13px;
    color: var(--secondary-text-color);
  }
}

.error-message {
  @include label-13-18;
  color: var(--main-red-color);
}
</style>
